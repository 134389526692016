<template>
  <v-app>

    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row ">
              <div class="col-md-12">
                <router-link :to="{name:'dashboard'}">
                  Dashboard
                </router-link>
                \
                <router-link :to="{name:'email-template'}">
                  Email Template
                </router-link>
                \
                Update email templates
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>Email templates</h4>
                <div class="mt-1">
                  <span class="p-1" v-bind:class="{ 'badge-success': email_template.score_id, 'badge-primary': !email_template.score_id }"> {{ email_template.score_id ? 'Custom template for '+email_template.score_name : 'Default Template' }}</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 text-right mb-3">

              </div>
              <v-row>
                <v-col cols="8">
                  <v-col cols="12">
                    <h6>{{ email_template.title }}</h6>
                  </v-col>

                  <v-col cols="12">
                    <b>Identifier: </b> {{email_template.identifier}}
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        outlined
                        dense
                        label="Title"
                        v-model="email_template.title"
                        @input="debouncedCheckForAcceptedInput"
                    >

                    </v-text-field>
                    <span v-if="contentTitleNotAllowed" class="text-danger">
                      Special character cannot be used for title
                    </span>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      outlined
                      dense
                      :items="recipientTypes"
                      item-text="name"
                      item-value="value"
                      v-model="email_template.recipient_type"
                    >
                      <template v-slot:label>
                        Recipient <span class="text-danger"></span>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        outlined
                        dense
                        label="Email Subject"
                        v-model="email_template.subject"
                        @input="debouncedCheckForAcceptedInput"
                    >
                    </v-text-field>
                    <span v-if="contentInputNotAllowed" class="text-danger">
                      Special character cannot be used for subject
                    </span>
                  </v-col>

                  <v-col cols="12">
                    Trigger (An action that will send this email)
                    <ckeditor 
                      :config="getEditorConfig()"
                      v-model="email_template.about_template"
                    ></ckeditor>
                  </v-col>

                  <v-col cols="12">
                    <strong>
                      Content
                    </strong>
                    <ckeditor v-model="email_template.description" 
                      :config="getEditorConfig()"
                    ></ckeditor>
                  </v-col>
                </v-col>
                <v-col cols="4">

                  <v-col cols="12">
                    <strong>Accepted content inputs</strong>
                  </v-col>

                  <v-col cols="12" v-if="accepted_inputs.length">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-for="(input,index) in accepted_inputs " :key="index"
                               class="ma-2 text-lowercase"
                               color="success"
                               outlined
                               label
                               dark
                               v-bind="attrs"
                               v-on="on"
                               v-clipboard:copy="`{{${input}}}`"
                               v-clipboard:success="onCopy"
                               v-clipboard:error="onError"
                        >
                          {{ input }}
                        </v-btn>
                      </template>
                      <span>Copy</span>
                    </v-tooltip>

                  </v-col>
                  <v-col cols="12">
                    <v-switch label="Active" v-model="email_template.is_active">

                    </v-switch>
                  </v-col>
                  <v-col cols="12">

                    <v-btn @click="redirectedTo" class="cancel-btn mr-2" text x-large>
                      Cancel
                    </v-btn>

                    <v-btn @click.prevent="__update" :disabled="contentInputNotAllowed || contentTitleNotAllowed" :loading="is_loading" class="btn btn-primary text-white"  x-large>
                      Save
                    </v-btn>
                  </v-col>

                </v-col>
              </v-row>

            </div>
          </div>
        </div>
      </div>
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    </div>
  </v-app>
</template>
<script>
import EmailTemplateService from "@/services/email-template/EmailTemplateService";
import { debounce } from 'lodash';

const emailTemplate = new EmailTemplateService();
export default {
  name: "Email-Template",
  components: {},
  data() {
    return {
      text: null,
      timeout: 2000,
      is_loading: false,
      snackbar: false,
      email_template: null,
      email_templates: [],
      accepted_inputs: [],
      email_types: [
        {name: 'Pre Registration Acknowledge', value: 'pre_registration_acknowledge'},
      ],
      items: [],
      search: null,
      contentInputNotAllowed: false,
      contentTitleNotAllowed: false,
      recipientTypes:[
        {
          name: 'Enroller',
          value: 'enroler'
        },
        {
          name: 'Examiner',
          value: 'examiner'
        },
        {
          name: 'Supervisor',
          value: 'supervisor'
        },
        {
          name: 'Online marker',
          value: 'online_marker'
        },
        {
          name: 'Admin',
          value: 'admin'
        },
      ]
    };
  },
  mounted() {
    this.getSingleEmailTemplate(this.email_template_id);
  },
  created() {
    this.debouncedCheckForAcceptedInput = debounce(this.checkForAcceptedInput, 500);
  },
  computed: {
    email_template_id() {
      return this.$route.params.id;
    }
  },
  watch: {
    model(val) {
      if (val.length > 5) {
        this.$nextTick(() => this.model.pop())
      }
    },
  },
  methods: {
    getSingleEmailTemplate(id) {
      emailTemplate
          .show(id)
          .then(response => {
        this.email_template = response.data.email_template;
        if (this.email_template.accepted_inputs) {
          this.accepted_inputs = this.email_template.accepted_inputs.split(',');
        }
      }).catch(error => {
      }).finally(() => {

      })
    },
    redirectedTo() {
      this.$router.push({
        name: "email-template",
        params: {role: this.email_template.role}
      });
    },
    __update() {
      this.is_loading = true;
      this.email_template.accepted_inputs = this.accepted_inputs.join(',');
      emailTemplate.update(this.email_template.id, this.email_template).then(response => {
        this.$snotify.success('Email template updated')
        this.getSingleEmailTemplate(this.email_template_id);
        this.$tabs.close();
        this.$router.push({name: "email-template"});
      }).catch(error => {

      }).finally(() => {
        this.is_loading = false;

      })
    },

    getEditorConfig(){
      return {
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'RemoveFormat', 'Strike',"NumberedList" ,'BulletedList', 'Image', 'Link']
        ],
        versionCheck: false
      }
    },

    checkForAcceptedInput(){
      this.contentInputNotAllowed = false;
      this.contentTitleNotAllowed = false;
      
      if (this.email_template.subject.includes('{')) {
        this.contentInputNotAllowed = true;
      }

      if (this.email_template.title.includes('{')) {
        this.contentTitleNotAllowed = true;
      }
    },

    onCopy: function (e) {
      this.text = "You just copied accepted input ";
      this.snackbar = true;
    },
    onError: function (e) {
      this.text = "Failed to copy accepted input";
      this.snackbar = true;
    },
  }
};
</script>
